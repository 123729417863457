<template>
    <b-card>
      <b-card-header>
        <i class="icon-note"></i> Detail Crypto Transaction History
        <div class="card-header-actions">
          <a class="card-header-action" href="crypto-transaction" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <table>
          <tr>
              <td><strong>Transaction Date</strong></td>
              <td>{{ this.created_at }}</td>
          </tr>
          <tr>
              <td><strong>User</strong></td>
              <td>{{ this.user }} </td>
          </tr>
          <tr>
              <td><strong>Transaction Type</strong></td>
              <td>{{ this.transaction_type }}</td>
          </tr>
          <tr>
              <td><strong>Asset Kripto</strong></td>
              <td>{{ this.source_crypto }}</td>
          </tr>
          <tr>
              <td><strong>Nomor Invoice</strong></td>
              <td>{{ this.invoice }}</td>
          </tr>
          <tr>
              <td><strong>Reference Number</strong></td>
              <td>{{ this.reference_number }}</td>
          </tr>
          <tr>
              <td><strong>Description</strong></td>
              <td><strong>{{ this.description }}</strong></td>
          </tr>
          <tr>
              <td><strong>Asset Amount</strong></td>
              <td>{{ this.trs_amount }}</td>
          </tr>
          <tr>
              <td><strong>Asset TKO Amount</strong></td>
              <td>{{ this.asset_amount }}</td>
          </tr>
          <tr>
              <td><strong>IDR Amount</strong></td>
              <td>{{ this.amount }}</td>
          </tr>
          <tr>
              <td><strong>Asset Buy TRS Price</strong></td>
              <td>{{ this.buying_rate }}</td>
          </tr>
          <tr>
              <td><strong>Asset Sell TRS Price</strong></td>
              <td>{{ this.selling_rate }}</td>
          </tr>
          <tr v-if="this.transaction_type === 'Buy Asset'">
              <td><strong>Metode Pembayaran</strong></td>
              <td v-if="this.payment_method === null">-</td>
              <td v-else>{{ this.payment_method }}</td>
          </tr>
          <tr>
              <td><strong>Transaction Status</strong></td>
              <td>{{ this.status }}</td>
          </tr>
      </table>
    </b-card>
</template>

<script>

  export default {
    data() {
      return {
        created_at: '',
        user: '',
        transaction_type: '',
        source_crypto: '',
        invoice: '',
        description: '',
        asset_amount: '',
        amount: '',
        trs_amount: '',
        buying_rate: '',
        selling_rate: '',
        reference_number: '',
        payment_method: '',
        status: '',
        errors: {
          code: '',
          message: '',
          status: ''
        },
      }
    },
    created() {
        this.$http.get(`crypto-transaction/` + this.$route.params.id)
        .then((result) => {
          let res = result.data.data;
          this.created_at = res.created_at
          this.user = res.user
          this.transaction_type = res.transaction_type
          this.source_crypto = res.source_crypto
          this.invoice = res.invoice
          this.description = res.description
          this.asset_amount = res.asset_amount
          this.trs_amount = res.trs_amount
          this.amount = res.amount
          this.buying_rate = res.buying_rate
          this.selling_rate = res.selling_rate
          this.status = res.status
          this.reference_number = res.order_id
          this.payment_method = res.payment_method
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    }
  }
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
